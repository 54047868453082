import "./App.css";
import { Homepage } from "./components/homepage/homepage";
import bg from "./components/Assets/istockphoto-1125872257-612x612.jpg"

function App() {
  
  return(
    <div className="homepage" style={{backgroundImage: `url(${bg}`}}>
     <Homepage />
    </div> );
}

export default App;
