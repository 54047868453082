import "./about.css";
import { cardArray } from "./aboutInfo";


export default function About(props) {
  return (
      <div className={props.size === "sm" ? "about-container sm" : "about-container"} id="About">
        {cardArray.map((card, index) => (
          <div className="card" key={index}>
            <span className="about-header">{card.header}</span>
            <span>{card.text}</span>
          </div>
        ))}
      </div>
  );
}
