export const cardArray = [
  {
    header: "List of Carriers",
    text: "Aetna, Devoted, Geisinger Gold, Highmark, Humana, Mutual of Omaha, SureBridge"
  },
  {
    header: "Types of Insurance",
    text: "Medigap, Medicare Advantage, Part D, Dental-Vision-Hearing, Cancer, Hospital Indemnity"
  }
];
