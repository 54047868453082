import "./meeting.css";

export default function Meeting(props) {

  return (
    <div className={props.size === "sm" ? "meeting sm" : "meeting"} id="Meeting">
      <h2>Meet and Greet Dates</h2>
      <span className="Date"> Tuesday, February 6,2024 4:00 PM
        McBride Memorial Library
        500 N Market Street
        Berwick, PA 18603</span>
      <span className="Date">Friday, February 9, 2024 4:00 PM
        Tunkhannock Public Library
        220 Tioga Street
        Tunkhannock, PA 18657</span>
      <span className="Date"> Monday, February 12, 2024 5:00 PM
        Taylor Community Library
        710 S Main Street
        Taylor, PA 18517</span>
      <span className="Date"> Monday, February 19, 2024 11:00 AM
        Pittston Memorial Highway
        47 Broad Street
        Pittston, PA 18640</span>
    </div>
  );
}
