import "./contact.css";

export default function Contact(props) {

  return (
    <div className={ props.size === "sm" ? "contact sm" : "contact" } id="Contact">
      <p>Contact Us</p>
      <p>Theresahillard76@gmail.com</p>
      <p>570-417-3472</p>
    </div>
  );
}
