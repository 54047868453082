import { useState } from "react";
import "./navbar.css";
import Hamburger from "hamburger-react";

export default function Navbar(props) {
  const [isOpen, setOpen] = useState(false);

  function scrollTo(id) {
    const eleID = document.getElementById(id);
    window.scroll({
      top: eleID.offsetTop,
      behavior: "smooth",
    });
  }

  if (props.size === "sm")
    return (
      <div className="navbar sm">
        <h2>Simplified Medicare Solutions by Theresa Hillard</h2>
        {/* <div className='navbuttons sm'>
          <Hamburger toggled={isOpen} toggle={setOpen} />
          {isOpen ? (
            <>
              <button onClick={() => scrollTo("About")}>About Us</button>
              <button onClick={() => scrollTo("Contact")}>Contact</button>
            </>
          ) : null}
        </div> */}
      </div>
    );

  else return (
    <div className="navbar">
      <h2>Simplified Medicare Solutions by Theresa Hillard</h2>
      {/* <div className="navbuttons">
        <button onClick={() => scrollTo("About")}>About Us</button>
        <button onClick={() => scrollTo("Contact")}>Contact</button>
      </div> */}
    </div>
  );
}
