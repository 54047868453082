import "./summary.css";
import photo from "../Assets/IMG_3998003.jpg"

export default function Summary(props) {
    return (
        <div className="abtUs">
            <div className="summary">
                <h2>About Us</h2>
                <p>Hi, I am Theresa Hillard, an independent Medicare Advisor. I have been in the industry since 1985 and selling Medicare plans since 2011.  I strive to be one of the most respected Medicare Advisors in the market.</p>
            </div>
            <img src={photo} alt="photo" />
        </div>
    );

}
